<template>
	<div class="my-container p-all-20">
		<myHeader :title="i18n.tx" :height="260">
			<template slot="content">
				<div class="the-content p-all">
					<div class="text-left">
						<span>{{ i18n.tbbz }}</span>
					</div>
					<div class="input-item">
						<div class="select-box">
							<mySelect :selectStyle="selectStyle" :icon.sync="icon" :selectIndex.sync="selectIndex"
								:options="options" key_value="label" :is_show.sync="is_show">
							</mySelect>
						</div>
					</div>
					<div class="dis-60"></div>
					<div class="text-left">
						<span>{{ i18n.tbsl }}</span>
					</div>
					<div class="input-item">
						<van-field v-model="amount" :placeholder="i18n.place">
							<div slot="extra" class="the-max" @click="maxIt()">
								<span class="f-30">MAX</span>
							</div>
						</van-field>
					</div>
					<div class="d-space-row f-22"  v-if="selectIndex > 0">
						<span>{{ i18n.fee }}</span>
						<span>{{ parseFloat(wallet.fee).toFixed(2) }}%</span>
					</div>
					<div class="d-space-row f-22"  v-else>
						<span>{{ i18n.fee }}</span>
						<span>3 USDT</span>
					</div>
					<div class="d-space-row f-22" v-if="selectIndex == 0">
						<span>LELE</span>
						<span>{{ parseFloat(wallet.arc).toFixed(4) }}</span>
					</div>
					<div class="d-space-row f-22" v-if="selectIndex == 1">
						<span>ARB：</span>
						<span>{{ parseFloat(wallet.arb).toFixed(4) }}</span>
					</div>
					<div class="d-space-row f-22" v-if="selectIndex == 2">
						<span>ETH</span>
						<span>{{ parseFloat(wallet.eth).toFixed(4) }}</span>
					</div>
					<div class="buy-btn max-bg d-center-row" @click="nowGet()">
						<span class="f-30">{{ i18n.qrtx }}</span>
					</div>
				</div>
			</template>
		</myHeader>
	</div>
</template>

<script>
import {
	balance,
	withdrawSign
} from "@/api/account.js";
import mySelect from '../components/mySelect.vue'
export default {
	components: {
		mySelect
	},
	data() {
		return {
			wallet: {
				arc: 0,
				arb: 0
			},
			selectStyle: {
				width: '100%',
				// 3个130,2个90
				height: '130px',
				top: '40px',
				left: '0px'
			},
			is_show: false,
			selectIndex: 0,
			icon: require("../../assets/image/lele-icon.png"),
			options: [{
				label: 'LELE',
				value: 1,
				icon: require("../../assets/image/lele-icon.png")
			},
			{
				label: 'ARB',
				value: 2,
				icon: require("../../assets/image/arb-icon.png")
			},
			{
				label: 'ETH',
				value: 3,
				icon: require("../../assets/image/arb-icon.png")
			},
			],

			amount: ''
		};
	},
	computed: {
		i18n() {
			return this.$t('my')
		}
	},
	created() {
		this.getBalance()
	},
	methods: {
		async nowGet() {
			if (this.amount == '' || parseFloat(this.amount) <= 0) {
				return
			}
			await this.$dapp.onConnect();
			const {
				err,
				res
			} = await withdrawSign({
				amount: this.amount,
				coinType: this.selectIndex + 1
			});
			if (err == null) {
				if (this.selectIndex < 2) {
					const pool = await this.$dapp.pool();
					const [error, tx] = await pool.getRewardByAction(res);
					if (error === null) {
						this.amount = ''
						this.getBalance()
						this.$toast(this.$t("app.opeSuccess"));
					}
				} else {
					const ethPool = await this.$dapp.ethPool();
					const [error, tx] = await ethPool.getRewardByAction(res);
					if (error === null) {
						this.amount = ''
						this.getBalance()
						this.$toast(this.$t("app.opeSuccess"));
					}

				}




			}
		},
		async getBalance() {
			const {
				err,
				res
			} = await balance();
			if (err === null) {
				this.wallet = res;
			}
		},
		maxIt() {
			if (this.selectIndex == 0) {
				this.amount = this.wallet.arc
			} else if (this.selectIndex == 1) {
				this.amount = this.wallet.arb
			} else {
				this.amount = this.wallet.eth
			}
		}
	}

}
</script>

<style lang="scss" scoped>
.my-container {
	.the-content {
		width: 100%;
		z-index: 8;
	}

	.input-item {
		width: 100%;
		height: 103px;
		background: #FFFFFF;
		border: 1px solid #46B996;
		border-radius: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 10px 0;

		img {
			width: 56px;
			height: 56px;
		}

		.the-max {
			color: #4BB005;
			box-sizing: border-box;
		}

		.select-box {
			width: 100%;
			height: 70px;
			background: #FFFFFF;
			// border: 1px solid #D0C6B5;
			border-radius: 10px;
		}
	}

	.buy-btn {
		width: 565px;
		height: 77px;
		background: url('../../assets/image/btn-bg.png');
		margin: 0 auto;
		margin-top: 60px;
	}
}
</style>